import { version, createApp } from "vue";
import App from "./App.vue";
import router from "@/router/router";
import Quasar from 'quasar/src/vue-plugin.js';;
import { createPinia } from 'pinia'


import { VueReCaptcha } from 'vue-recaptcha-v3'
import LoadScript from 'vue-plugin-load-script';


import quasarUserOptions from "./quasar-user-options";

console.log("Vue version " + version);

var app
try {
  app = createApp(App);
  const pinia = createPinia()



  app.use(VueReCaptcha, { siteKey: '6Les7gcmAAAAAH0DoWoH92BbhrcNRa5bAx0aYdMF' })
  app.use(pinia)
  app.use(router);
  app.use(Quasar, quasarUserOptions);
  app.use(LoadScript)

  app.mount("#app");


} catch (e) {
  document.getElementById("apperr").innerHTML = "Error: " + e;
}

export { app }
