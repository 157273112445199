
import './styles/quasar.scss'
//import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
//import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
//import '@quasar/extras/material-icons-round/material-icons-round.css'
//import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
//import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'


import Loading from 'quasar/src/plugins/Loading.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
      Loading
  },

   extras : [
 //   "fontawesome-v5.css",
//    "material-icons-outlined",
    "material-icons",
    "mdi-v7",
  ]
}
