import { createRouter, createWebHistory  } from 'vue-router'


const Panel = () => import('@/layouts/Panel')
const Checkout = () => import('@/layouts/Checkout')

const Privacy = () => import('@/pages/Privacy')
const Terms = () => import('@/pages/Terms')
const Refund = () => import('@/pages/Refund')
const Shipping = () => import('@/pages/Shipping')
const TrackMyOrder = () => import('@/pages/TrackMyOrder')
const OrderInfo = () => import('@/pages/OrderInfo')
const CartInfo = () => import('@/pages/CartInfo')
const About = () => import('@/pages/About')
const Contact = () => import('@/pages/Contact')
const Main = () => import('@/pages/Main')
const FAQ = () => import('@/pages/FAQ')
const BlogList = () => import('@/pages/BlogList')
const ProductList = () => import('@/pages/ProductList')
const ProductInfo = () => import('@/pages/ProductInfo')
const ProductInstanceInfo = () => import('@/pages/ProductInstanceInfo')
const CreateProductInstance = () => import('@/pages/CreateProductInstance')
const NotFound = () => import('@/pages/NotFound')

const CheckoutStepInfo = () => import('@/pages/CheckoutStepInfo')
const CheckoutStepShipping = () => import('@/pages/CheckoutStepShipping')
const CheckoutStepPayment = () => import('@/pages/CheckoutStepPayment')

const PayerPost = () => import('@/pages/PayerPost')
const PayerFail = () => import('@/pages/PayerFail')
const PayerSuccess = () => import('@/pages/PayerSuccess')

const router = createRouter({
	linkActiveClass: 'active',
	scrollBehavior: () => ({ y: 0 }),
  history: createWebHistory(),
	routes: configRoutes()
})


function configRoutes() {
	return [
		{
			path: '/',
			name : 'Panel',
			component: Panel,
      children: [
        {
          name: 'Privacy', path: 'privacy-policy', component: Privacy, props: true
        },
        {
          name: 'Terms', path: 'terms-of-service', component: Terms, props: true
        },
        {
          name: 'Refund', path: 'refund-policy', component: Refund, props: true
        },
        {
          name: 'Shipping', path: 'shipping-policy', component: Shipping, props: true
        },
        {
          name: 'About', path: 'about', component: About, props: true
        },
        {
          name: 'TrackMyOrder', path: 'track-my-order', component: TrackMyOrder, props: true
        },
        {
          name: 'ProductList', path: 'books', component: ProductList, props: true
        },
        {
          name: 'OrderInfoWithID', path: 'order/:id', component: OrderInfo, props: true
        },
        {
          name: 'OrderInfo', path: 'order', component: OrderInfo, props: true, meta: {'orderID':''}
        },
        {
          name: 'CartInfo', path: 'cart', component: CartInfo, props: true
        },
        {
          name: 'ProductInstanceInfoWithID', path: 'book/:id', component: ProductInstanceInfo, props: true
        },
        {
          name: 'ProductInstanceInfo', path: 'book', component: ProductInstanceInfo, props: true, meta: {'productInstanceID':''}
        },
        {
          name: 'ProductInfo', path: 'products/:slug', component: ProductInfo, props: true
        },
        {
          name: 'CreateProductInstance', path: 'products/:slug/create', component: CreateProductInstance, props: true
        },
        {
          name: 'FAQ', path: 'faqs', component: FAQ, props: true
        },
        {
          name: 'BlogList', path: 'blog', component: BlogList, props: true
        },
        {
          name: 'BlogArticle', path: 'blog/:id', component: BlogList, props: true
        },
        {
          name: 'Contact', path: 'contact', component: Contact, props: true
        },
        {
          name: 'Main', path: '', component: Main, props: true
        },
        {
          name: 'PayerPost', path: 'payer', component: PayerPost, props: true
        },
        {
          name: 'PayerFail', path: 'payment/fail', component: PayerFail, props: true
        },
        {
          name: 'PayerSuccess', path: 'payment/success', component: PayerSuccess, props: true
        },
      ]
		},
    {
      name: 'Checkout', 
      path: '/checkout', 
      component: Checkout, 
      props: true,
       redirect: () => {
        return { path: '/checkout/info' }
      },
      children: [
        {
          name: 'CheckoutStepInfo', path: 'info', component: CheckoutStepInfo, props: true
        },
        {
          name: 'CheckoutStepShipping', path: 'shipping', component: CheckoutStepShipping, props: true
        },
        {
          name: 'CheckoutStepPayment', path: 'payment', component: CheckoutStepPayment, props: true
        }
      ]
    },



    {
      path: "/:catchAll(.*)",
			beforeEnter: () => {
        console.log("yyy")
				window.location = `/404`
			},
      
      component: NotFound, // does not really matter
    }

	]
}

export default router

